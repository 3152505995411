import Vue from 'vue';
import TYPES from '@/types';

import { v4 } from 'uuid';

// Application
import GetRetirementFundLocationQuery from '@/modules/flagship/catalogs/retirement-fund-location/application/queries/get-retirement-fund-location-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  RetirementFundLocationEntity,
} from '@/modules/flagship/catalogs/retirement-fund-location/domain/entities/retirement-fund-location-entity';
import {
  CreateEmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/create-emergency-fund-investor-goal-state-manager';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipGoalsWizardRetirementFundSecondModel {
  @Inject(TYPES.GET_RETIREMENT_FUND_LOCATION_QUERY)
  private readonly get_retirement_fund_location_query!: GetRetirementFundLocationQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.CREATE_EMERGENCY_FUND_INVESTOR_GOAL_STATE_MANAGER)
  private readonly emergency_fund_investor_goal_state!: CreateEmergencyFundInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace =
    'components.flagship.flagship-goals.flagship_goals_wizard_retirement_fund_second';

  investor_profile_id = '';

  retirement_investor_goal_id = v4();

  retirement_fund_location_options: Array<RetirementFundLocationEntity> = [];

  selected_options: Array<RetirementFundLocationEntity> = [];

  total_savings = 0;

  initial_saving_amount = 0;

  investor_goal_state = this.emergency_fund_investor_goal_state.state;

  readonly view!: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get is_continue_btn_disabled() {
    return !(this.selected_options.length !== 0) || this.investor_goal_state.is_loading;
  }

  get total_savings_formatted() {
    return Math.trunc(this.total_savings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get is_total_savings_more_than_zero() {
    return parseFloat(String(this.total_savings).replace(/[^0-9.]/g, ''));
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  loadRetirementFundLocationOptions = async () => {
    try {
      this.retirement_fund_location_options = await this.get_retirement_fund_location_query
        .execute();
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.get_retirement_fund_location'));
    }
  }

  getRetirementInvestorGoalDataIfExists = () => {
    this.investor_goal_state.retirement.id = this.retirement_investor_goal_id;
    this.total_savings = this.investor_goal_state.retirement.initial_amount;
    this.initial_saving_amount = this.investor_goal_state.retirement.initial_amount_adjusted;
  }

  getInvestorProfile = async () => {
    try {
      const { retirement_fund_locations, id } = await this
        .get_investor_profile_query.execute(false);
      this.investor_profile_id = id;
      if (retirement_fund_locations && Object.keys(retirement_fund_locations).length > 0) {
        // eslint-disable-next-line max-len
        if (retirement_fund_locations.values) {
          this.selected_options = retirement_fund_locations.values;
        }
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  updateInitialAmount = async () => {
    const initial_amount = Number(parseFloat(this.total_savings.toString().replaceAll(/[^\d.-]/g, '')).toFixed(2));
    const initial_amount_adjusted = Number(parseFloat(this.initial_saving_amount.toString().replaceAll(/[^\d.-]/g, '')).toFixed(2));
    this.investor_goal_state.retirement.initial_amount = initial_amount;
    this.investor_goal_state.retirement.initial_amount_adjusted = initial_amount_adjusted;
  }

  updateRetirementFundLocationOnInvestorProfile = async () => {
    try {
      await this.updateInitialAmount();
      await this.update_investor_profile_command.execute({
        id: this.investor_profile_id,
        retirement_fund_locations: {
          retirement_amount: parseFloat(String(this.initial_saving_amount).replace(/[^0-9.]/g, '')),
          values: this.selected_options,
        },
        retirement_investor_goal: {
          issued_age: this.investor_goal_state.retirement.issued_age,
          retirement_age_adjusted: this.investor_goal_state.retirement.retirement_age_adjusted,
          retirement_range_adjusted: this.investor_goal_state.retirement.retirement_range_adjusted
            .toString(),
          pension_range_adjusted: this.investor_goal_state.retirement.pension_range_adjusted,
          monthly_pension_adjusted: this.investor_goal_state.retirement.monthly_pension_adjusted
            .toString(),
          monthly_required_amount: this.investor_goal_state.retirement.monthly_required_amount,
          initial_amount: this.investor_goal_state.retirement.initial_amount,
          initial_amount_adjusted: this.investor_goal_state.retirement.initial_amount_adjusted,
          accumulated_amount: this.investor_goal_state.retirement.accumulated_amount,
        },
      });
      return true;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.update_retirement_fund_location_on_investor_profile'));
      return false;
    }
  }

  initialize = async () => {
    this.investor_goal_state.is_loading = true;
    this.view.$emit('loadingStep', true);
    await this.loadRetirementFundLocationOptions();
    await this.getRetirementInvestorGoalDataIfExists();
    await this.getInvestorProfile();
    this.investor_goal_state.is_loading = false;
    this.view.$emit('loadingStep', false);
  };

  onSubmit = async () => {
    this.investor_goal_state.is_loading = true;
    const stored = await this.updateRetirementFundLocationOnInvestorProfile();
    this.investor_goal_state.is_loading = false;
    if (stored) {
      this.view.$emit('nextStep');
    }
  }
}
